import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#00000000",
    },
    error: {
      main: "#eb645d",
    },
    success: {
      main: "#57ca81",
    },
    text: {
      primary: "#ffffff",
      secondary: "#ffffff",
      disabled: "#9E9E9E",
    },
  },
  shape: {
    borderRadius: 16,
  },
});

export default theme;
