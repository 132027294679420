import React, { useEffect, useState } from "react";
import { useWeb3Modal } from "@web3modal/react";
import { useDisconnect } from "wagmi";
import { connectorLocalStorageKey } from "../ConnectWalletModal";
import styled from "styled-components";
import BackButtonImage from "../../assets/images/navbar/backButton.png";
import NavSubLongLeftImage from "../../assets/images/navbar/navSubLongHeadingLeft.png";
import NavSubLongRightImage from "../../assets/images/navbar/navSubLongHeadingRight.png";
import NavSubLeftImage from "../../assets/images/navbar/navSubHeadingLeft.png";
import NavSubRightImage from "../../assets/images/navbar/navSubHeadingRight.png";
import NavHeadingImage from "../../assets/images/navbar/navHeadingWithText.png";
import MaticImage from "../../assets/images/navbar/maticImage.png";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import { ethers } from "ethers";
import BigNumber from "bignumber.js";

const NavMainContainer = styled.div``;
const NavigationContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 1023px) {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
`;
const BackButtonContainer = styled.div`
  max-width: 200px;
  width: 100%;

  @media (max-width: 1023px) {
    display: none;
  }
`;
const BackButton = styled.img``;

const NavHeadingContainer = styled.div`
  display: flex;
`;
const NavSubHeadingLongLeftBox = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`;
const NavSubHeadingLongLeft = styled.img``;

const NavSubHeadingLeftBox = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`;
const NavSubHeadingLeft = styled.img``;

const NavHeadingBox = styled.div``;
const NavHeading = styled.img``;

const NavSubHeadingRightBox = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`;
const NavSubHeadingRight = styled.img``;

const NavSubHeadingLongRightBox = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`;
const NavSubHeadingLongRight = styled.img``;

export const ConnectButton = styled.div`
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #2d242d;
  background: #90a9aa;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  -webkit-text-stroke: 0.7px black;
  max-width: 145px;
  width: 100%;
  margin: 0 auto;

  &:hover {
    cursor: pointer;
  }
`;

const ConnectedButton = styled.div`
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #2d242d;
  background: #90a9aa;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  -webkit-text-stroke: 0.7px black;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;

  &:hover {
    cursor: pointer;
  }
`;

// export const truncateAddress = (address: string) => {
//   if (!address) return "No Account";
//   const match = address.match(
//     /^(0x[a-zA-Z0-9]{0})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/,
//   );
//   if (!match) return address;
//   return `${match[1]}…${match[2]}`;
// };

const ConnectButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
  max-width: 200px;
  width: 100%;
`;
const MaticImageBox = styled.div``;
const MaticNtwImage = styled.img``;
const FetchContentBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 5px;
`;
const MaticBalanceBox = styled.div``;
// const AccountBox = styled.div``;

const Menu: React.FC = () => {
  const { account } = useActiveWeb3React();
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();

  const [userBalance, setUserBalance] = useState("0");

  const deactivate = () => {
    disconnect();
    window.localStorage.removeItem(connectorLocalStorageKey);
  };

  const getAccountBalance = async (acc: string) => {
    const provider = new ethers.providers.JsonRpcProvider(
      process.env.REACT_APP_RPC_URL,
    );

    const balanceOfUser = new BigNumber(
      (await provider.getBalance(acc)).toString(),
    )
      .dividedBy(10 ** 18)
      .toFixed(3);

    setUserBalance(() => balanceOfUser);
  };

  useEffect(() => {
    if (account) {
      getAccountBalance(account).catch((e) =>
        console.log("Error in getAccountBalance: ", e),
      );
    }
  }, [account]);

  return (
    <NavMainContainer>
      <NavigationContainer>
        <BackButtonContainer>
          <a href="https://garden.arkadiapark.com/">
            <BackButton src={BackButtonImage} />
          </a>
        </BackButtonContainer>

        <NavHeadingContainer>
          <NavSubHeadingLongLeftBox>
            <NavSubHeadingLongLeft src={NavSubLongLeftImage} />
          </NavSubHeadingLongLeftBox>

          <NavSubHeadingLeftBox>
            <NavSubHeadingLeft src={NavSubLeftImage} />
          </NavSubHeadingLeftBox>

          <NavHeadingBox>
            <NavHeading src={NavHeadingImage} />
          </NavHeadingBox>

          <NavSubHeadingRightBox>
            <NavSubHeadingRight src={NavSubRightImage} />
          </NavSubHeadingRightBox>

          <NavSubHeadingLongRightBox>
            <NavSubHeadingLongRight src={NavSubLongRightImage} />
          </NavSubHeadingLongRightBox>
        </NavHeadingContainer>

        <ConnectButtonContainer>
          {account ? (
            <div>
              <ConnectedButton onClick={deactivate}>
                <MaticImageBox>
                  <MaticNtwImage src={MaticImage} alt="MATIC network" />
                </MaticImageBox>
                <FetchContentBox>
                  <MaticBalanceBox>{userBalance} MATIC</MaticBalanceBox>
                  {/*<AccountBox>{`${truncateAddress(account)}`}</AccountBox>*/}
                </FetchContentBox>
              </ConnectedButton>
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <ConnectButton onClick={() => open()}>Connect</ConnectButton>
            </div>
          )}
        </ConnectButtonContainer>
      </NavigationContainer>
    </NavMainContainer>
  );
};

export default Menu;
