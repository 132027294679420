import { createGlobalStyle } from "styled-components";
import backgroundImageSVG from "../assets/images/backgroundImage.svg";

const ResetCSS = createGlobalStyle`
  * {
    font-family: 'Roboto', sans-serif;
    -webkit-text-stroke: inherit;
  }

  /* prettier-ignore */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    font-family: 'Roboto', sans-serif;
  }

  /* HTML5 display-role reset for older browsers */
  /* prettier-ignore */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 25px;
    font-size: 16px;
    background-color: #261F26;
    background-image: url(${backgroundImageSVG});
    min-height: 100vh;
    width: 100%;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }

  ol,
  ul {
    list-style: disc inside;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  [role="button"] {
    cursor: pointer;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.primary.main};
    border-radius: 1px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #000;
    border-radius: 1px;
  }

  /* Slider */
  input[type=range] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
  }

  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type=range]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;
    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  .ReactModal__Overlay{
    z-index: 99 !important;
    background: transparent !important;
  }

  .ReactModal__Content{
    border: none !important;
    background: transparent !important;
  }
  .MuiTooltip-tooltipPlacementBottom {
    background-color: #CFF3FF;
    padding: 10px 20px;
    color: black;
    font-size: 16px;
  }
`;

export default ResetCSS;
