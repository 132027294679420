// @ts-nocheck

import addresses from "../config/contracts";

interface Address {
  80001: string;
  137?: string;
}
export const getAddress = (address: Address): string => {
  let chainId =
    window && window.ethereum
      ? window.ethereum.networkVersion
      : process.env.REACT_APP_CHAIN_ID;
  return address[chainId || process.env.REACT_APP_CHAIN_ID];
};

export const getEip1155Address = () => {
  return getAddress(addresses.eip1155Contract);
};
export const getConvertorContractAddress = () => {
  return getAddress(addresses.convertorContract);
};
export const getMulticallVAddress = () => {
  return getAddress(addresses.multiCall);
};
