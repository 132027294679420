export const AllContractAddresses = {
  feeManager: {
    56: "0x84cE3FC303EB48E1803Fd0aaB7bD59A6b33e4107",
    97: "0x84cE3FC303EB48E1803Fd0aaB7bD59A6b33e4107",
    80001: "0xd42e896E9C850E9A8392997242C691184dB8481B",
    5: "0x6C2Ba4AF80D07BE26Ab81Cf5d2ab6FFdB7d7F35C",
    137: "0x76977525f15B815d41c7497ef6FAd809E82C7DbF",
    1: "0x7bA6671929110274D7f48e6fed9A2bDB83b2FBCd",
    1287: "0x6c6931059F53FEAc06c215c9a9430E6eDDf5fBa9",
  },
  router: {
    56: "",
    97: "",
    80001: "0xE86A68cd5A994D94BB7049acE481e9a6b5Fd94d6",
    5: "0x6C2Ba4AF80D07BE26Ab81Cf5d2ab6FFdB7d7F35C",
    137: "0xBd13225f0a45BEad8510267B4D6a7c78146Be459",
    1: "0x6C2Ba4AF80D07BE26Ab81Cf5d2ab6FFdB7d7F35C",
    1287: "0x2d4e873f9Ab279da9f1bb2c532d4F06f67755b77",
  },
  eip1155Contract: {
    56: "",
    97: "",
    80001: "0x5D4B4577333E18C5111eFbe96204e8FCFB5d1C42",
    5: "0x6C2Ba4AF80D07BE26Ab81Cf5d2ab6FFdB7d7F35C",
    137: "0x179D19089f660Ca97d931C1FCbE0649C1AeDc480",
    1: "0x6C2Ba4AF80D07BE26Ab81Cf5d2ab6FFdB7d7F35C",
    1287: "0x2d4e873f9Ab279da9f1bb2c532d4F06f67755b77",
  },
  convertorContract: {
    56: "",
    97: "",
    80001: "0xe718d68eB8EF2660FA95E6BF51ECd93AaffD6cdE",
    5: "0x6C2Ba4AF80D07BE26Ab81Cf5d2ab6FFdB7d7F35C",
    137: "0xf9b80ee0924114a878612d1b35F9Aa8e2E85c5C0",
    1: "0x6C2Ba4AF80D07BE26Ab81Cf5d2ab6FFdB7d7F35C",
    1287: "0x2d4e873f9Ab279da9f1bb2c532d4F06f67755b77",
  },
  multiCall: {
    56: "0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B",
    97: "0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576",
    80001: "0x27A9fca03eD753f0729E3A86a6123d8C5c90b8e2",
    5: "0x27A9fca03eD753f0729E3A86a6123d8C5c90b8e2",
    137: "0x429876c4a6f89FB470E92456B8313879DF98B63c",
    1: "0x5e227AD1969Ea493B43F840cfF78d08a6fc17796",
    1287: "0xf09FD6B6FF3f41614b9d6be2166A0D07045A3A97",
  },
};

export default AllContractAddresses;