import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import styled from "styled-components";
import {
  getEip1155Contract,
  getConvertorContract,
} from "../../utils/contractHelpers";
import { getConvertorContractAddress } from "../../utils/addressHelpers";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import HairBox from "../../assets/images/hero/hairBox.png";
import FrenBox from "../../assets/images/hero/frenBox.png";
import ModalFren from "../../assets/images/hero/modalFren.png";
import erc20ContractAbi from "../../config/abi/erc20.json";
import convertorContractAbi from "../../config/abi/convertorContract.json";
import multicallV2 from "../../utils/multicall";
import BigNumber from "bignumber.js";
import { claimData, FREN_TOTAL_SUPPLY } from "../../config";
import Modal from "react-modal";
import { Tooltip } from "@mui/material";
import { Info } from "@mui/icons-material";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

const MainButton = styled.button`
  padding: 10px 40px;
  margin: 10px 0;
  border-radius: 10px;
  border: 1px solid #2d242d;
  background: #90a9aa;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  text-align: center;
  -webkit-text-stroke: 1px black;

  &:hover {
    cursor: pointer;
  }

  &:disabled{
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
const MainButtonText = styled.div`
  color: #fff;
  font-size: 25px;
  font-weight: 800;
  letter-spacing: 0.088px;
`;
const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 100px 20px;
  flex-wrap: wrap;
`;
const LearnMoreBox = styled.div`
  padding: 10px 40px;
  border-radius: 10px;
  border: 1px solid #2d242d;
  background: #90a9aa;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  -webkit-text-stroke: 0.7px black;
  margin: 0 80px;

  @media (max-width: 720px) {
    margin: 0 5px;
  }
`;
// const MaticBalanceBox = styled.div`
//   padding: 10px 20px;
//   border-radius: 10px;
//   border: 1px solid #2d242d;
//   background: #90a9aa;
//   box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
//   color: #fff;
//   text-align: center;
//   font-size: 24px;
//   font-style: normal;
//   font-weight: 800;
// `;
const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  flex-wrap: wrap;
  position: relative;
`;
const HairContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;
const HairContentBox = styled.div`
  margin-top: 50px;
  margin-right: -15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
`;
const HairBalance = styled.div`
  border-radius: 10px;
  border: 1px solid #2d242d;
  background: #cff3ff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  color: #fff;
  text-align: center;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 22px;
  font-weight: 800;
  padding: 10px 20px;
  -webkit-text-stroke: 1px black;
`;
const HairBalanceValue = styled.div`
  border-radius: 10px;
  border: 1px solid #2d242d;
  background: #cff3ff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  color: #fff;
  text-align: center;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 22px;
  font-weight: 800;
  padding: 10px 20px;
  -webkit-text-stroke: 1px black;
`;
const HairImageBox = styled.div`
  z-index: 85;
`;
const HairImage = styled.img``;
const FrenContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
const FrenContentBox = styled.div`
  margin-top: 50px;
  margin-left: -15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`;
const FrenMinted = styled.div`
  border-radius: 10px;
  border: 1px solid #2d242d;
  background: #cff3ff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  color: #fff;
  text-align: center;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 22px;
  font-weight: 800;
  padding: 10px 20px;
  -webkit-text-stroke: 1px black;
`;
const FrenMintedValue = styled.div`
  border-radius: 10px;
  border: 1px solid #2d242d;
  background: #cff3ff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  color: #fff;
  text-align: center;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 22px;
  font-weight: 800;
  padding: 10px 20px;
  -webkit-text-stroke: 1px black;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
`;
const FrenModalButton = styled.div`
  border-radius: 10px;
  border: 1px solid #2d242d;
  background: #cff3ff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  color: #fff;
  text-align: center;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 22px;
  font-weight: 800;
  padding: 10px 20px;
  -webkit-text-stroke: 1px black;
`;
const FrenImageBox = styled.div`
  z-index: 85;
`;
const FrenImage = styled.img``;
const ConversionBox = styled.div`
  position: absolute;
  bottom: 0;
  border-radius: 10px;
  border: 1px solid #2d242d;
  background: #90a9aa;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 10px 40px;
  color: #fff;
  text-align: center;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  font-weight: 800;
  z-index: 86;
  -webkit-text-stroke: 1px black;
`;
const SwapButtonContainer = styled.div``;
const LogicContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 30px;
  flex-direction: column;

  @media (max-width: 720px) {
    margin-top: 100px;
  }
`;
const ModalInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 88;

  max-width: 388px;
  width: 100%;
  border-radius: 8px;
  border: 2px solid #2d242d;
  background: #cff3ff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 25px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
`;
const ModalHeadingBox = styled.div``;
const ModalHeading = styled.div`
  color: #fff;
  text-align: center;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
  -webkit-text-stroke: 1px black;
`;
const ModalImageBox = styled.div``;
const ModalImage = styled.img``;
const ModalListBox = styled.div`
  color: #fff;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 23px;
  font-weight: 800;
  -webkit-text-stroke: 0.7px black;
`;
const ModalListUl = styled.ul`
  line-height: 30px;
`;
const ModalListLi = styled.li`
  font-size: 18px;
  line-height: 25px;
`;
const StyledTooltip = styled(Tooltip)``;

const Home: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const handleFrenModalOpen = () => setIsModalOpen(true);
  const handleFrenModalClose = () => setIsModalOpen(false);

  const [, setTokenData] = useState({
    tokenBalance: "0",
    tokenName: "",
    tokenSymbol: "",
    tokenDecimals: "",
    tokenAddress: "",
    allowance: "0",
  });
  const [hairNftBalance, setHairNftBalance] = useState("0");
  const [, setWigNftBalance] = useState("0");
  const [isApproved, toggleApproved] = useState(false);
  // const [amount, setAmount] = useState("0");
  // const [claimTx, toggleClaimTx] = useState(false);
  const [approveTx, toggleApproveTx] = useState(false);
  // const [approveForBuyTx, toggleApproveForBuyTx] = useState(false);
  const [mintTx, toggleMintTx] = useState(false);
  const [nftContractDetails, setNftContractDetails] = useState({
    hairTokenId: null,
    minimumTokenBalanceToBurn: "0",
    wigNftToMint: null,
    merkleProof: [""],
    quantityLimitPerWallet: "0",
    pricePerToken: "0",
    currency: "0",
    wigTokenId: null,
    totalFernMinted: "0",
  });
  const { account } = useActiveWeb3React();

  // const claimNft = async () => {
  //   try {
  //     const provider = new ethers.providers.Web3Provider(window.ethereum);
  //     const signer = provider.getSigner();
  //     const nftContract = getEip1155Contract(signer);
  //     toggleClaimTx(true);
  //     const allowListProof = {
  //       proof: claimData.merkleProof,
  //       quantityLimitPerWallet: claimData.quantityLimitPerWallet,
  //       pricePerToken: claimData.pricePerToken,
  //       currency: claimData.currency,
  //     };
  //     const tx = await nftContract.claim(
  //       account,
  //       nftContractDetails.hairTokenId,
  //       amount,
  //       claimData.currency,
  //       claimData.pricePerToken,
  //       allowListProof,
  //       "0x",
  //       {
  //         value: new BigNumber(claimData.pricePerToken)
  //           .multipliedBy(amount)
  //           .toString(),
  //       },
  //     );
  //     await tx.wait();
  //     const getHairBalance = await nftContract.balanceOf(account, 0);
  //     setHairNftBalance(getHairBalance.toString());
  //     toggleClaimTx(false);
  //   } catch (error) {
  //     toggleClaimTx(false);
  //     console.log(error);
  //   }
  // };
  const mintWigNft = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const nftContract = getEip1155Contract(signer);
      const convertorContract = getConvertorContract(signer);
      toggleMintTx(true);
      const tx = await convertorContract.swapTokens();
      await tx.wait();
      const getHairBalance = await nftContract.balanceOf(
        account,
        nftContractDetails.hairTokenId,
      );
      setHairNftBalance(getHairBalance.toString());
      const getWigBalance = await nftContract.balanceOf(
        account,
        nftContractDetails.wigTokenId,
      );
      setWigNftBalance(getWigBalance.toString());
      toggleMintTx(false);
    } catch (error) {
      toggleMintTx(false);
      console.log("Error in mintWigNft(swap): ", error);
    }
  };
  const approveNftContract = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const nftContract = getEip1155Contract(signer);
      const convertorContractAddress = getConvertorContractAddress();
      toggleApproveTx(true);
      const tx = await nftContract.setApprovalForAll(
        convertorContractAddress,
        true,
      );
      await tx.wait();
      toggleApproved(true);
      toggleApproveTx(false);
    } catch (error) {
      toggleApproveTx(false);
      console.log("Error in approveNftContract: ", error);
    }
  };
  // const handleChange = (e: any) => {
  //   const regex = /^[0-9\b]+$/;
  //   if (e.target.value === "" || regex.test(e.target.value)) {
  //     setAmount(e.target.value);
  //   }
  // };
  // const approveErc20 = async () => {
  //   try {
  //     if (account) {
  //       const provider = new ethers.providers.Web3Provider(window.ethereum);
  //       const signer = provider.getSigner();
  //       const nftContractAddress = getEip1155Address();
  //       toggleApproveForBuyTx(true);
  //       const erc20Contract = getERC20Contract(claimData.currency, signer);
  //       const approvalAmount = new BigNumber(amount)
  //         .multipliedBy(claimData.pricePerToken)
  //         .multipliedBy(10 ** parseFloat(tokenData.tokenDecimals))
  //         .toString();
  //       const approveTx = await erc20Contract.approve(
  //         nftContractAddress,
  //         approvalAmount,
  //       );
  //       await approveTx.wait();
  //       const allowance = await erc20Contract.allowance(
  //         account,
  //         nftContractAddress,
  //       );
  //       setTokenData((token) => ({
  //         ...token,
  //         allowance: new BigNumber(allowance.toString())
  //           .dividedBy(10 ** parseFloat(tokenData.tokenDecimals))
  //           .toString(),
  //       }));
  //       toggleApproveForBuyTx(false);
  //     }
  //   } catch (error) {
  //     toggleApproveForBuyTx(false);
  //     toggleApproveTx(false);
  //     console.log("Error in approveErc20: ", error);
  //   }
  // };

  useEffect(() => {
    const getAccountDetails = async () => {
      const provider = new ethers.providers.JsonRpcProvider(
        process.env.REACT_APP_RPC_URL,
      );
      const convertorContractAddress = getConvertorContractAddress();
      if (account) {
        const nftContract = getEip1155Contract();
        if (
          claimData.currency.toLowerCase() ===
          "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE".toLowerCase()
        ) {
          const balanceOfUser = new BigNumber(
            (await provider.getBalance(account)).toString(),
          )
            .dividedBy(10 ** 18)
            .toString();
          setTokenData({
            tokenBalance: balanceOfUser,
            tokenName: "Matic",
            tokenSymbol: "MATIC",
            tokenDecimals: "18",
            tokenAddress: claimData.currency,
            allowance: "0",
          });
        }
        if (nftContractDetails.hairTokenId) {
          const getHairBalance = await nftContract.balanceOf(
            account,
            nftContractDetails.hairTokenId,
          );
          setHairNftBalance(getHairBalance.toString());
        }
        if (nftContractDetails.wigTokenId) {
          const getWigBalance = await nftContract.balanceOf(
            account,
            nftContractDetails.wigTokenId,
          );
          setWigNftBalance(getWigBalance.toString());
        }
        const isApproved = await nftContract.isApprovedForAll(
          account,
          convertorContractAddress,
        );
        toggleApproved(isApproved);
        if (
          claimData.currency.toLowerCase() !==
          "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE".toLowerCase()
        ) {
          const tokenCalls = [
            {
              address: claimData.currency,
              name: "balanceOf",
              params: [account],
            },
            {
              address: claimData.currency,
              name: "allowance",
              params: [account, convertorContractAddress],
            },
          ];
          const [balanceOf, allowance] = await multicallV2(
            erc20ContractAbi,
            tokenCalls,
          );
          setTokenData((tokens) => ({
            ...tokens,
            tokenBalance: new BigNumber(balanceOf[0].toString())
              .dividedBy(10 ** claimData.decimals)
              .toString(),
            allowance: new BigNumber(allowance[0].toString())
              .dividedBy(10 ** claimData.decimals)
              .toString(),
          }));
        }
      }
    };
    const getNftContractDetails = async () => {
      const nftContract = getEip1155Contract();
      const convertorContractAddress = getConvertorContractAddress();
      const calls = [
        {
          address: convertorContractAddress,
          name: "hairTokenId",
        },
        {
          address: convertorContractAddress,
          name: "minimumTokenBalanceToBurn",
        },
        {
          address: convertorContractAddress,
          name: "wigNftToMint",
        },
        {
          address: convertorContractAddress,
          name: "wigTokenId",
        },
        {
          address: convertorContractAddress,
          name: "minimumTokenBalanceToBurn",
        },
      ];
      const nftDetailsResp = await multicallV2(convertorContractAbi, calls);
      if (nftDetailsResp) {
        const [
          hairTokenId,
          minimumTokenBalanceToBurn,
          wigNftToMint,
          wigTokenId,
        ] = nftDetailsResp;
        const getTotalFernMinted = await nftContract.totalSupply(
          wigTokenId[0].toString(),
        );
        if (
          claimData.currency.toLowerCase() ===
          "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE".toLowerCase()
        ) {
          setNftContractDetails({
            hairTokenId: hairTokenId[0].toString(),
            minimumTokenBalanceToBurn: minimumTokenBalanceToBurn[0].toString(),
            wigNftToMint: wigNftToMint[0].toString(),
            merkleProof: claimData.merkleProof,
            quantityLimitPerWallet: claimData.quantityLimitPerWallet,
            currency: claimData.currency,
            totalFernMinted: getTotalFernMinted.toString(),
            wigTokenId: wigTokenId[0].toString(),
            pricePerToken:
              new BigNumber(claimData.pricePerToken.toString())
                .dividedBy(10 ** 18)
                .toString() || "0",
          });
          setTokenData((tokens) => ({
            ...tokens,
            tokenName: "Matic",
            tokenSymbol: "MATIC",
            tokenDecimals: "18",
            tokenAddress: claimData.currency,
          }));
        } else {
          setNftContractDetails({
            hairTokenId: hairTokenId[0].toString(),
            minimumTokenBalanceToBurn: minimumTokenBalanceToBurn[0].toString(),
            wigNftToMint: wigNftToMint[0].toString(),
            merkleProof: claimData.merkleProof,
            quantityLimitPerWallet: claimData.quantityLimitPerWallet,
            pricePerToken: claimData.pricePerToken,
            currency: claimData.currency,
            totalFernMinted: getTotalFernMinted.toString(),
            wigTokenId: wigTokenId[0].toString(),
          });
        }
        if (
          claimData.currency.toLowerCase() !==
          "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE".toLowerCase()
        ) {
          const tokenCalls = [
            {
              address: claimData.currency,
              name: "name",
            },
            {
              address: claimData.currency,
              name: "symbol",
            },
            {
              address: claimData.currency,
              name: "decimals",
            },
          ];
          const [name, symbol, decimals] = await multicallV2(
            erc20ContractAbi,
            tokenCalls,
          );
          setNftContractDetails((nftData: any) => ({
            ...nftData,
            pricePerToken:
              new BigNumber(claimData.pricePerToken.toString())
                .dividedBy(10 ** decimals[0])
                .toString() || "0",
          }));
          setTokenData((tokens) => ({
            ...tokens,
            tokenName: name[0],
            tokenSymbol: symbol[0],
            tokenDecimals: decimals[0],
            tokenAddress: claimData.currency,
          }));
        }
      }
    };
    getNftContractDetails().catch((e) =>
      console.log("Error in getNftContractDetails: ", e),
    );
    if (account) {
      getAccountDetails().catch((e) =>
        console.log("Error in getAccountDetails: ", e),
      );
    }
  }, [account, nftContractDetails.hairTokenId, nftContractDetails.wigTokenId]);

  return (
    <>
      <LogicContainer>
        <MainContainer>
          <HairContainer>
            <HairContentBox>
              <HairBalance>Balance</HairBalance>
              <HairBalanceValue>{hairNftBalance}</HairBalanceValue>
            </HairContentBox>
            <HairImageBox>
              <HairImage src={HairBox} alt="Hair NFT" />
            </HairImageBox>
          </HairContainer>
          <FrenContainer>
            <FrenImageBox>
              <FrenImage src={FrenBox} alt="Fren NFT" />
            </FrenImageBox>
            <FrenContentBox>
              <FrenMinted>Minted</FrenMinted>
              <FrenMintedValue>
                {nftContractDetails.totalFernMinted} / {FREN_TOTAL_SUPPLY}
                <StyledTooltip
                  title={`
                  This event will mint only 33 Purple FRENs. 
                  The total supply for Purple FREN is 333.
                  `}
                  enterTouchDelay={0}
                >
                  <Info
                    style={{
                      background: "black",
                      borderRadius: "50%",
                      width: "15px",
                      height: "15px",
                    }}
                  />
                </StyledTooltip>
              </FrenMintedValue>
              <FrenModalButton onClick={handleFrenModalOpen}>!</FrenModalButton>
            </FrenContentBox>
          </FrenContainer>
          <ConversionBox>
            {nftContractDetails.minimumTokenBalanceToBurn} HAIR = 1 FREN
          </ConversionBox>
        </MainContainer>
        <SwapButtonContainer>
          {isApproved ? (
            <MainButton
              onClick={mintWigNft}
              disabled={
                mintTx ||
                parseFloat(nftContractDetails.minimumTokenBalanceToBurn) >
                  parseFloat(hairNftBalance) ||
                parseFloat(hairNftBalance) === 0
              }
            >
              <MainButtonText>
                {mintTx ? "Processing..." : "Swap"}
              </MainButtonText>
            </MainButton>
          ) : (
            <MainButton
              onClick={approveNftContract}
              disabled={
                approveTx ||
                parseFloat(nftContractDetails.minimumTokenBalanceToBurn) >
                  parseFloat(hairNftBalance)
              }
            >
              <MainButtonText>
                {approveTx ? "Processing..." : "Approve"}
              </MainButtonText>
            </MainButton>
          )}
        </SwapButtonContainer>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={handleFrenModalClose}
          aria-labelledby="The Purple Fren"
          aria-describedby="Information about the purple fren"
        >
          <ModalInner>
            <ModalHeadingBox>
              <ModalHeading>The Purple Fren</ModalHeading>
            </ModalHeadingBox>
            <ModalImageBox>
              <ModalImage src={ModalFren} alt="The Purple Fren NFT" />
            </ModalImageBox>
            <ModalListBox>
              <ModalListUl>
                Your best FREN can:
                <ModalListLi>Water ALL Garden</ModalListLi>
                <ModalListLi>2X Lucky Drop Chance</ModalListLi>
                <ModalListLi>Save Forest from Burning</ModalListLi>
              </ModalListUl>
            </ModalListBox>
            <MainButton onClick={handleFrenModalClose}>
              <MainButtonText>Return</MainButtonText>
            </MainButton>
          </ModalInner>
        </Modal>
      </LogicContainer>
      <FooterContainer>
        <a href="https://discord.gg/Yvdtpawx">
          <LearnMoreBox>Learn More</LearnMoreBox>
        </a>
      </FooterContainer>
    </>
  );
};

export default Home;
