// @ts-nocheck

import React from "react";
import { ModalProvider, ResetCSS } from "cryption-uikit-v2";
import GlobalStyle from "./GlobalStyle";
import { ThemeContextProvider } from "./ThemeContext";
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { arbitrum, mainnet, polygon } from 'wagmi/chains'

const Providers: React.FC = ({ children }) => {
  const chains = [arbitrum, mainnet, polygon]
  const projectId = '2ad969628058e68d8f8883ce26b636a0'

  const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, version: 1, chains }),
    publicClient
  })
  const ethereumClient = new EthereumClient(wagmiConfig, chains)
  return (
    <WagmiConfig config={wagmiConfig}>
      <ThemeContextProvider>
        <ModalProvider>
          <ResetCSS />
          <GlobalStyle />
          {children}
        </ModalProvider>
      </ThemeContextProvider>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </WagmiConfig>
  );
};

export default Providers;
