import erc20Abi from "../config/abi/erc20.json";
import simpleRpcProvider from "./defaultProvider";
import type { Signer } from "@ethersproject/abstract-signer";
import type { Provider } from "@ethersproject/providers";
import { Contract } from "@ethersproject/contracts";
import { Erc20 } from "../config/abi/types";
import {
  getConvertorContractAddress,
  getEip1155Address,
  getMulticallVAddress,
} from "./addressHelpers";
import eip1155Abi from '../config/abi/eip1155.json'
import convertorContractAbi from "../config/abi/convertorContract.json";
import MulticallV2 from "../config/abi/MulticallV2.json"

export const getContract = (
  abi: any,
  address: string,
  signer?: Signer | Provider
) => {
  const signerOrProvider = signer ?? simpleRpcProvider;
  return new Contract(address, abi, signerOrProvider);
};

export const getERC20Contract = (
  address: string,
  signer?: Signer | Provider
) => {
  return getContract(erc20Abi, address, signer) as Erc20;
};

export const getEip1155Contract = (
  signer?: Signer | Provider
) => {
  return getContract(eip1155Abi, getEip1155Address(), signer);
};
export const getConvertorContract = (
  signer?: Signer | Provider
) => {
  return getContract(
    convertorContractAbi,
    getConvertorContractAddress(),
    signer
  );
};
export const getMulticallV2Contract = (signer?: Signer | Provider) => {
  return getContract(MulticallV2, getMulticallVAddress(), signer);
};
