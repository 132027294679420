import { Interface } from "@ethersproject/abi";
import { BytesLike } from "ethers";
import { CallOverrides } from "@ethersproject/contracts";
import { getMulticallV2Contract } from "./contractHelpers";

export interface Call {
  address: string; // Address of the contract
  name: string; // Function name on the contract (example: balanceOf)
  params?: any[]; // Function params
}

export interface MulticallOptions extends CallOverrides {
  requireSuccess?: boolean;
}
// @ts-ignore
const multicallV2 = async <T = any>(abi: any[], calls: Call[]): Promise<T> => {
  try {
    const multi = getMulticallV2Contract();
    const itf = new Interface(abi);

    const calldata = calls.map((call) => [
      call.address.toLowerCase(),
      itf.encodeFunctionData(call.name, call.params),
    ]);
    const { returnData } = await multi.callStatic.aggregate(calldata);
    return returnData.map((call: BytesLike, i: any) =>
      itf.decodeFunctionResult(calls[i].name, call)
    );
  } catch (error) {
    console.log("eeror", error);
  }
};

export default multicallV2;
