import BigNumber from "bignumber.js";

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

export const NATIVE_TOKENS = {
  80001: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: "18",
  },
  137: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: "18",
  },
  1: {
    name: "ETH",
    symbol: "ETH",
    decimals: "18",
  },
  1287: {
    name: "Glimmer",
    symbol: "GLMR",
    decimals: "18",
  },
};

export const MAINNET_CHAINID = 137;
export const SUPPORTED_NETWORK_IDS = [137, 80001];

export const claimData = {
  merkleProof: [
    "0x0000000000000000000000000000000000000000000000000000000000000000",
  ],
  quantityLimitPerWallet:
    "115792089237316195423570985008687907853269984665640564039457584007913129639935",
  pricePerToken: "10000000000000000",
  currency: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  decimals: 18,
};

export const FREN_TOTAL_SUPPLY = 33;
